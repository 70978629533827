export function parseError(error) {
  let parsedError = {
    code : "",
    status: "",
    message: ""
  }

  if (error.response) {
    parsedError.code = error.code
    parsedError.status = error.response.status
    parsedError.message = error.response.data.error
  } else {
    parsedError.code = error.code
    parsedError.status = "--"
    parsedError.message = error.message
  }

  return parsedError
}