export default [
  {
    icon: "home",
    text: "Inicio",
    path: "home",
    name: "CryptoboxHome",
    fileName: "HomeView",
  },
  {
    icon: "atm",
    text: "Listado",
    path: "btms",
    name: "BTMsView",
    meta: { permission: "BTM_GET_METHOD" },
    fileName: "BTMsView",
  },
  {
    path: "btms/:id",
    name: "BTMDetailView",
    meta: { permission: "BTM_GET_METHOD" },
    fileName: "BTMDetailView",
  },
  {
    icon: "map-pin",
    text: "Mapa",
    path: "btm-map",
    name: "BTMMapView",
    meta: { permission: "BTM_GET_METHOD" },
    fileName: "BTMMapView",
  },
  {
    icon: "list",
    text: "Operaciones",
    path: "operations",
    name: "OperationsView",
    meta: { permission: "BTM_TX_GET_METHOD" },
    fileName: "OperationsView",
  },
  {
    path: "operations/:id",
    name: "OperationDetailView",
    meta: { permission: "" },
    fileName: "OperationDetailView",
  },
  {
    icon: "clock",
    text: "Sesiones",
    path: "sessions",
    name: "SessionsView",
    fileName: "SessionsView",
  },
  {
    path: "sessions/:id",
    name: "SessionDetailView",
    fileName: "SessionDetailView",
  },
  {
    icon: "update",
    text: "Versiones",
    path: "versions",
    name: "VersionsView",
    fileName: "VersionsView",
  },
]