import { createRouter, createWebHistory } from "vue-router"

import UiRoutes from "./modules/ui.router.js"
import AuthRoutes from "./modules/auth.router.js"
import MontebitRoutes from "./modules/montebit.router.js"
import CryptoboxRoutes from "./modules/cryptobox.router.js"
import RayyoRoutes from "./modules/rayyo.router.js"
import { useAuthStore } from "../stores/auth.store.js"

const loadComponent = (component) => () => import(`@/views/${component}.vue`)

const routes = [
  ...UiRoutes,
  ...AuthRoutes,
  ...MontebitRoutes,
  ...CryptoboxRoutes,
  ...RayyoRoutes,
  {
    path: "/",
    redirect: "/loading",
  },
  {
    path: "/loading",
    name: "LoadingView",
    component: loadComponent("LoadingView"),
  },
  {
    path: '/:pathMatch(.*)*',
    name: "NoRouteView",
    component: loadComponent("NoRouteView"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  const loggedOutViews = AuthRoutes.map(v => v.name)
  const session = localStorage.getItem("op")
  const requiresAuth = to.matched.some((record) => record.meta.authRequired)
  const viewWithPermission = to.matched.find(record => record.meta.permission)
  const requiredPermission = viewWithPermission ? viewWithPermission.meta.permission : ""
  let operatorPermissions = authStore.state.operatorPermissions

  // Conditions
  const viewRequiresAuthAndThereIsNotSession = requiresAuth && !session
  const theresSessionAndViewIsLoggedOutOnly = loggedOutViews.includes(to.name) && session
  const operatorDoesNotHaveRequiredPermission = operatorPermissions.length > 0 && requiredPermission && !operatorPermissions.includes(requiredPermission)

  if (viewRequiresAuthAndThereIsNotSession) {
    next({ name: "LoginView" })
  } else if (
    theresSessionAndViewIsLoggedOutOnly ||
    operatorDoesNotHaveRequiredPermission
  ) {
    next({ name: "LoadingView" })
  } else {
    next()
  }
})

router.resolve({
  name: 'NoRouteView',
})

export default router