import { defineStore } from "pinia"
import { reactive } from "vue"
import { parseError } from "../helpers/error-parser"
import { postLogin, getOperatorSessionRequest, postOTPRequest, setPasswordRequest } from "../services/auth.api"
import * as Sentry from '@sentry/vue'

class AuthenticationError extends Error {
  constructor (errorData) {
    super(errorData.message)
    this.status = errorData.status
    this.name = this.constructor.name
    this.message = `${errorData.code} (${errorData.status}): ${errorData.message}`
  }
}

function errorHandler(error) {
  const parsedError = new AuthenticationError(parseError(error))
  Sentry.captureException(parsedError)
  return parsedError
}

export const useAuthStore = defineStore("auth", () => {
  const state = reactive({
    operatorData: {},
    operatorRoles: [],
    operatorPermissions: [],
  })

  async function makeLogin(data) {
    try {
      const operatorData = {
        username: data.username,
        password: data.password,
      }
      const res = await postLogin(operatorData)
      localStorage.setItem("op", res.data.data.token)
    } catch(error) {
      throw errorHandler(error)
    }
  }

  async function getOperatorSession() {
    try {
      const res = await getOperatorSessionRequest()
      state.operatorData = res.data.data
      state.operatorRoles = res.data.data.roles
      parseOperatorPermissions()
      Sentry.setUser({ username: res.data.data.loginName })
    } catch(error) {
      throw errorHandler(error)
    }
  }

  function parseOperatorPermissions() {
    const permissions = []
    
    for (let i = 0; i < state.operatorRoles.length; i++) {
      const role = state.operatorRoles[i]

      for (let j = 0; j < role.permissions.length; j++) {
        const permission = role.permissions[j]
        if (!permissions.includes(permission)) permissions.push(permission)
      }
    }

    state.operatorPermissions = permissions
  }

  async function postOTP(data) {
    try {
      await postOTPRequest(data)
    } catch(error) {
      throw errorHandler(error)
    }
  }

  async function setPassword(data) {
    try {
      await setPasswordRequest(data)
    } catch (error) {
      throw errorHandler(error)
    }
  }

  function logout() {
    localStorage.removeItem("op")
    state.operatorData = {}
    state.operatorRoles = []
    state.operatorPermissions = []
  }

  return { state, makeLogin, getOperatorSession, postOTP, logout, setPassword }
})