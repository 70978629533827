import routes from "../../data/rayyoRoutes.js"

const loadComponent = (component) => () => import(`@/views/Rayyo/${component}.vue`)

export default [
  {
    path: "/rayyo",
    name: "RayyoMain",
    component: loadComponent("MainView"),
    redirect: "/rayyo/home",
    meta: { authRequired: true },
    children: routes.map(route => {
      route.component = loadComponent(route.fileName)
      return route
    }),
  },
]
