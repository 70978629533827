import routes from "../../data/uiRoutes.js"

const loadComponent = (component) => () => import(`@/views/Ui/${component}.vue`)

export default [
  {
    path: "/ui",
    name: "MainView",
    component: loadComponent("MainView"),
    redirect: "/ui/autocomplete",
    children: routes.map(route => {
      route.component = loadComponent(route.name)
      return route
    }),
  }
]