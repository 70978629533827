<template>
  <RouterView />
</template>

<script setup>
import { onMounted } from "vue"
import { useGoogleMapsStore } from "./stores/google-maps.store.js"

const googleMapsStore = useGoogleMapsStore()

onMounted(() => {
  googleMapsStore.setGoogleMapsApi()
})
</script>
