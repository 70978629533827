import routes from "../../data/montebitRoutes.js"

const loadComponent = (component) => () => import(`@/views/Montebit/${component}.vue`)

export default [
  {
    path: "/montebit",
    name: "MontebitMain",
    component: loadComponent("MainView"),
    redirect: "/montebit/funds",
    meta: { authRequired: true },
    children: routes.map(route => {
      route.component = loadComponent(route.fileName)
      return route
    }),
  },
]
