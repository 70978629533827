const loadComponent = (component) => () => import(`@/views/Auth/${component}.vue`)

export default [
  {
    path: "/login",
    name: "LoginView",
    component: loadComponent("LoginView")
  },
  {
    path: "/set-password",
    name: "SetPasswordView",
    component: loadComponent("SetPasswordView")
  }
]