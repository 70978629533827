import * as Sentry from '@sentry/vue'

export function initSentry(app, router) {
  Sentry.init({
    app,
    dsn: "https://92605baba0e64304929fc63c2f190c45@o4504452674158592.ingest.sentry.io/4504452694212608",
    integrations: [Sentry.browserTracingIntegration({router}), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  })
}
