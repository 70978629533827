import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import { createPinia } from "pinia"
import "./scss/index.scss"
import { createHead } from '@unhead/vue'
import { initSentry } from "./plugins/sentry"

const head = createHead()
const pinia = createPinia()

const app = createApp(App)
initSentry(app, router)
app.use(router).use(head).use(pinia)
app.mount('#app')
