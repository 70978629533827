import routes from "../../data/cryptoboxRoutes.js"

const loadComponent = (component) => () => import(`@/views/Cryptobox/${component}.vue`)

export default [
  {
    path: "/cryptobox",
    name: "CryptoboxMain",
    component: loadComponent("MainView"),
    redirect: "/cryptobox/home",
    meta: { authRequired: true },
    children: routes.map(route => {
      route.component = loadComponent(route.fileName)
      return route
    }),
  },
]
