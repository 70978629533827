import { defineStore } from "pinia"
import { ref } from "vue"
import { Loader } from '@googlemaps/js-api-loader'

export const useGoogleMapsStore = defineStore("googleMaps", () => {
  const google = ref()

  function setGoogleMapsApi() {
    const VUE_APP_GOOGLE_MAPS_AKI_KEY = "AIzaSyBdBrD3IHjQw-P9W7tss6-UFmrlV7VQq_k"
    const loader = new Loader({
      apiKey: VUE_APP_GOOGLE_MAPS_AKI_KEY,
      version: "weekly",
    })
  
    loader.load().then(googleInstance => {
      google.value = googleInstance
    })
  }

  return { google, setGoogleMapsApi }
})